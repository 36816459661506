<template>
  <header-selete fixed placeholder="搜索手机号、用户昵称" @submit="resetList" ref="headerSelete"/>
  <xia-la-load ref="xiaLa" @load="getLoad">
    <van-cell-group v-if="total" :title="`共邀请 ${total} 个用户`" />
    <div class="tui-jian" v-for="(vo,index) in dataList" :key="index">
      <div class="tui-jian-image">
        <van-image class="user-avatar" width="56" height="56" :src="vo.avatar"/>
      </div>
      <div class="user-info">
        <div class="name">{{ vo.user_nickname }}</div>
        <div class="mobile">{{ vo.mobile }}</div>
        <div class="create-time">注册时间：{{ vo.create_time }}</div>
      </div>
    </div>
  </xia-la-load>
  <footer-nav fixed/>
</template>

<script>
import HeaderSelete from "../open/headerSelete";
import FooterNav from "../open/footerNav";
import XiaLaLoad from "../../components/list/XiaLaLoad";
import http from "../../api/http";
import moment from "moment";
import {hidden} from "../../api/GongJu";

/**
 * 推荐人
 */
export default {
  name: 'Recommender',
  components: {XiaLaLoad, FooterNav, HeaderSelete},
  mixins: [],
  data() {
    return {
      where: {},
      total: 0,
      dataList: []
    }
  },
  mounted() {
  },
  methods: {
    resetList() {
      this.$refs.xiaLa.resetData()
    },
    getLoad(page) {
      this.where.keyword = this.$refs.headerSelete.getKeyword()
      this.where.page = page
      http.get('/gzhphp/user/getWoDeyaoQing', {params: this.where}).then(da => {
        this.$refs.xiaLa.setLoadSuccess2(da).then(({page, item, total}) => {
          item = item.map(n => {
            n.create_time = moment(n.create_time * 1000).format('YYYY-MM-DD')
            n.mobile = hidden(n.mobile, 3, 4)
            return n
          }) //修改数据
          this.total = total
          if (page === 1) this.dataList = item
          else this.dataList = [...this.dataList, ...item]
        })
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped lang="less">
@import "../../assets/basis_css/style/var";

.tui-jian {
  background-color: @white;
  margin: 10px;
  padding: 10px;
  box-shadow: @yin-ying;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: flex-start;
  align-items: center;

  .tui-jian-image {
    width: 66px;

    .user-avatar {
      border-radius: 5px;
      overflow: hidden;
    }
  }

  .user-info {
    font-size: 16px;

    .create-time {
      font-size: 14px;
      color: @gray-6;
    }
  }
}
</style>
